@keyframes slideUp {
    from {
        transform: translateY(60%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slideUp {
    opacity: 0; /* Start with invisible */
    animation: slideUp 1500ms ease-out forwards; /* Changed animation to slideUp */
    animation-delay: 500ms; /* Delay for staggering */
}
.hidden{
    opacity: 0;
}

.fade-out {
    opacity: 0; /* Start as invisible */

    animation: fadeOutAnimation 2s forwards;
}

@keyframes fadeOutAnimation {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}