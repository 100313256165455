.bannerImgOne{
    background-image: url('https://evolvus.s3.ap-south-1.amazonaws.com/public/website/assets/Images/home/homeBannerOne.jpg'); /* Replace with your image path */
    background-size: cover; /* Makes the image cover the entire element */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: right;
}
.bannerImgOneMobile{
    background-image: url('https://evolvus.s3.ap-south-1.amazonaws.com/public/website/assets/Images/home/homeBannerOneMobile.jpg'); /* Replace with your image path */
    background-size: cover; /* Makes the image cover the entire element */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: right;
}

.bannerImgTwo{
    background-image: url('https://evolvus.s3.ap-south-1.amazonaws.com/public/website/assets/Images/home/homeBannerTwo.jpg'); /* Replace with your image path */
    background-size: cover; /* Makes the image cover the entire element */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: right;
}
.bannerImgTwoMobile{
    background-image: url('https://evolvus.s3.ap-south-1.amazonaws.com/public/website/assets/Images/home/homeBannerTwoMobile.jpg'); /* Replace with your image path */
    background-size: cover; /* Makes the image cover the entire element */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: right;
}
.bannerImgThree{
    background-image: url('https://evolvus.s3.ap-south-1.amazonaws.com/public/website/assets/Images/home/homeBannerThree.jpg'); /* Replace with your image path */
    background-size: cover; /* Makes the image cover the entire element */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: right;
}
.bannerImgThreeMobile{
    background-image: url('https://evolvus.s3.ap-south-1.amazonaws.com/public/website/assets/Images/home/homeBannerThreeMobile.jpg'); /* Replace with your image path */
    background-size: cover; /* Makes the image cover the entire element */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-position: right;
}
.bannerText{
    position: absolute;
    top:45%;
    font-size:70px;
    max-width: 60%;
    line-height: 70px;
    padding-left: 150px;
}

.nextIcon{
    display: flex;
    opacity: .6;
    align-items: center;
    justify-content: center;
    background-color: var(--gray);
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.carousel-indicators button{
    background-color: black !important;

}

.carousel-indicators .active {
    background-color: black !important;
}

@media only screen and (max-width: 992px) {
    .bannerText{
        position: absolute;
        margin-top: auto;
        text-align: center;
        margin-bottom: auto;
        font-size:50px;
        max-width: 100%;
        padding-left: 0;
        line-height: 60px;
        padding-right: 0;
    }

}
.carousel-item {
    transition: transform 1.5s ease-in-out !important;
}

@keyframes fadeUpCard {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.cardanimation {
    opacity: 0;
    animation: fadeUpCard 1s ease-out forwards;
    transition: opacity 1s ease-out, transform 1s ease-out;
}
.hidden{
    opacity: 0;
}