
@keyframes slideIn {
    from {
        transform: translateX(250px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}


.bannerTextAnimation {
    opacity: 0; /* Start with invisible */
    animation: slideIn 900ms ease-out forwards;
    animation-delay: 500ms; /* Delay for staggering */
}


@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(250px);
        opacity: 0;
    }
}

.slideOut {
    opacity: 0; /* Start with invisible */
    animation: slideOut 900ms ease-in forwards;
    animation-delay: 500ms; /* Delay for staggering */
}

@keyframes slideUp {
    from {
        transform: translateY(10%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slideUp {
    opacity: 0; /* Start with invisible */
    animation: slideUp 1500ms ease-out forwards; /* Changed animation to slideUp */
    animation-delay: 500ms; /* Delay for staggering */
}

.slideUpSlowly {
    opacity: 0; /* Start with invisible */
    animation: slideUp 1000ms ease-out forwards; /* Changed animation to slideUp */
    animation-delay: 700ms; /* Delay for staggering */
}

.slideUpQuickly {
    opacity: 0; /* Start with invisible */
    animation: slideUp 900ms ease-out forwards; /* Changed animation to slideUp */
    animation-delay: 0ms; /* Delay for staggering */
}

/* Define the fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Apply the animation to a class */
.fade-in {
    opacity: 0; /* Start as invisible */
    animation: fadeIn 0.8s ease-in forwards; /* 1s fade-in effect */
    animation-delay: 1s; /* Start the animation after a 3-second delay */
    visibility: hidden; /* Keep hidden until animation starts */
}

.fade-in-delay {
    visibility: visible; /* Reveal element once animation starts */
}

/* Apply the animation to a class */
.fade-in-long {
    opacity: 0; /* Start as invisible */
    animation: fadeIn 2s ease-in forwards; /* 1s fade-in effect */
    animation-delay: 2s; /* Start the animation after a 3-second delay */
    visibility: hidden; /* Keep hidden until animation starts */
}

.fade-in-long-delay {
    visibility: visible; /* Reveal element once animation starts */
}

.fade-in-without-hidden {
    opacity: 0; /* Start as invisible */
    animation: fadeIn 0.5s ease-in forwards; /* 1s fade-in effect */
}

.fade-in-without-hidden-data {
    opacity: 0; /* Start as invisible */
    animation: fadeIn 0.5s ease-in forwards; /* 1s fade-in effect */
    animation-delay: 0.8s; /* Start the animation after a 3-second delay */
}


@keyframes underlineExpand {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.underline-separator {
    position: absolute;
    bottom: -5px; /* Adjust to place the line right below the text */
    left: 0;
    margin-bottom: 15px;
    height: 5px; /* Line thickness */
    width: 0; /* Start hidden */
    background-color: green; /* Adjust color as needed */
    animation: underlineExpand 1500ms ease-in-out forwards;
  /* Delay if you want a staggered effect */
     /* Keep hidden until animation starts */
}


.underline-separator-delay {
    visibility: visible; /* Reveal element once animation starts */
}

.underline-separator2 {
    position: absolute;
    bottom: -5px; /* Adjust to place the line right below the text */
    left: 0;
    margin-bottom: 15px;
    height: 5px; /* Line thickness */
    width: 0; /* Start hidden */
    background-color: green; /* Adjust color as needed */
    animation: underlineExpand 1500ms ease-in-out forwards;
    /* Delay if you want a staggered effect */
     /* Keep hidden until animation starts */
}


.underline-separator2-delay {
    visibility: visible; /* Reveal element once animation starts */
}

.underline-separator3 {
    position: absolute;
    bottom: -5px; /* Adjust to place the line right below the text */
    left: 0;
    margin-bottom: 15px;
    height: 5px; /* Line thickness */
    width: 0; /* Start hidden */
    background-color: green; /* Adjust color as needed */
    animation: underlineExpand 1500ms ease-in-out forwards;
    /* Delay if you want a staggered effect */
     /* Keep hidden until animation starts */
}


.underline-separator3-delay {
    visibility: visible; /* Reveal element once animation starts */
}

.vertical-counter::after {
    content: attr(data-to);
}

@keyframes slideUpFadeIn {
    from {
        transform: translateY(10%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slideUpFadeIn {
    opacity: 0; /* Start as invisible */
    animation: slideUpFadeIn 1500ms ease-out forwards;
    animation-delay: 500ms; /* Optional delay for staggering */
}


@keyframes slideDown{
    from {
        transform: translateY(-60%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.slideDown {
    opacity: 0; /* Start with invisible */
    animation: slideDown 1500ms ease-out forwards; /* Changed animation to slideUp */
    animation-delay: 500ms; /* Delay for staggering */
}

.scroll-animation {
    transform: translateY(30px) scale(1); /* Initial state */
    transition: transform 2s ease-out;
}

.scroll-animation.animate {
    transform: translateY(-50px) scale(1.1); /* Final state when scrolled */
}