* {
    margin: 0;
}

::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #656565;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.evolvus-label-mobile {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-lg);
    color: var(--primary);
    cursor: pointer;
}

.body {
    font-size: var(--font-size-sm);
    color: var(--black-text);
    background-color: var(--white);
    font-weight: var(--font-style-normal);
}

#root {
    color: var(--black-text);
    font-size: var(--font-size-sm);
    background-color: var(--white);
    font-weight: var(--font-style-normal);
}

:root { /* Colors: */

    --primary: #282828;
    --secondary: #FF9800;
    --success-light: #72bf44;
    --danger: #D72F2F;
    --gray-light: #f7f7f7;
    --gray-dark: #666666;
    --gray : #e4e4e4 ;
    --disable: #999999;
    --black : #282828;
    --white: #fff;
    --link: #0073ff;
    --warning: #F8B24F;
    --info: #E7EFFF;

    --primary-text: #282828;
    --secondary-text: #FF9800;
    --success-text: #72bf44;
    --error-text: #D72F2F;
    --white-text: #FFFFFF;
    --black-text: #333333;
    --disabled-text: #999999;

    --font-family-inter: 'Poppins', sans-serif;
    --font-style-initial: 100;
    --font-style-low: 200;
    --font-style-normal: normal;
    --font-weight-bolder: 700;
    --font-weight-bold: 600;
    --font-weight-semibold: 500;
    --font-weight-normal: normal;


    --font-size-xxs: 10px;
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-md: 16px;
    --font-size-lg: 20px;
    --font-size-xl: 24px;
    --font-size-xxl: 28px;
    --font-size-xxxl: 32px;
    --font-size-xxxxl: 36px;
    --character-spacing-0: 0px;
    --line-spacing-18: 18px;
    --line-spacing-20: 20px;
}

.panel {
    background: var(--white) 0 0% no-repeat padding-box;
    box-shadow: 0 0 6px #00000029;
    border: 1px solid var(--gray);
    border-radius: 8px;
    opacity: 1;
}

.theme-font-section-title {
    font-size: var(--font-size-xxl);
}

.theme-font-content {
    font-size: var(--font-size-sm);
}

.theme-font-content-lg {
    font-size: var(--font-size-lg);
    line-height: 1.5;
}

.theme-font-content-xs {
    font-size: var(--font-size-xs);
}

.theme-text-on-secondary-bg {
    color: var(--secondary-text)
}

.theme-text-primary {
    color: var(--primary)
}

.theme-bg-white {
    background-color: var(--white);
}

.theme-font-semiBold {
    font-weight: var(--font-weight-semibold);
}

.theme-font-bold {
    font-weight: var(--font-weight-bold);
}

.theme-font-bolder {
    font-weight: var(--font-weight-bolder);
}

/*for tablet view only*/
@media only screen and (max-width: 992px) {
    .page-container {
        padding-right: 50px;
        padding-left: 50px;
    }

    .theme-font-section-title { /*h2*/
        font-size: var(--font-size-lg);
    }

    .theme-font-content { /*h4*/
        font-size: var(--font-size-sm);
    }

    .theme-font-content-lg { /*h4*/
        font-size: var(--font-size-sm);
        line-height: 1.5;
    }
}

/*for mobiles only*/
@media only screen and (max-width: 768px) {

    .theme-font-section-title {
        font-size: var(--font-size-lg);
    }

    .theme-font-content { /*h4*/
        font-size: var(--font-size-sm);
    }

    .theme-font-content-lg { /*h4*/
        font-size: var(--font-size-sm);
        line-height: 1.5;
    }
}

a {
    text-decoration: unset !important;
}

h1 {
    margin-bottom: 0;
}

.list-icons {
    font-size: 5px;
    color: #717580;
    opacity: 0.7;
}

.blog-image {
    overflow: hidden;
    position: relative;
}

.blog-image img {
    width: 100%;
    /*height: 100%;*/
    transition: transform 1.5s ease;
}

.blog-image:hover img {
    transform: scale(1.1);
}

.news-event-Heading {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--primary);
    line-height: 30px;
    text-align: left;
    margin-bottom: 8px;
    text-decoration-skip-ink: none;
}

.news-event-subHeading {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    color: var(--gray-dark);
    line-height: 21px;
    text-align: left;
    text-decoration-skip-ink: none;
}

.news-event-readmoreCSS {
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
    line-height: 21px;
    text-align: left;
    color: #1C64F2;
}

.news-event-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #232323;
}

.news-event-subTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #232323;
}

.services-section-title {
    font-size: 24px;
    margin-top: 48px;
    margin-bottom: 32px;
    font-weight: 600;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #232323;
}

.services-section-subTitle {
    font-size: 18px;
    font-weight: 500;
    margin-top: 28px;
    margin-bottom: 16px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #232323;
}

.services-section-subHeading {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #232323;
}


.home-title{
    font-weight: var(--font-style-normal);
    font-size: var(--font-size-xxxl);
    color: var(--gray-dark)
}


@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 576px) {
    .home-title{
        font-weight: var(--font-style-normal);
        font-size: var(--font-size-xl);
        color: var(--gray-dark)
    }
    .about-count{
        font-size: var(--font-size-xl) !important;
    }
}

.block-sub-title{
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-md);
    color: var(--black);
}

.card-box-shadow{
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 12px 0 rgba(0, 0, 0, 0.04);
    min-height: 270px;
}

.home-green-dot{
    background-color: var(--success-light);
    height: 15px;
    width: 15px;
    margin-right: 8px;
}

.gradient-btn {
    background: linear-gradient(80deg,#0066b3,var(--success-light)); /* Gradient colors */
    color: white; /* Text color */
    border: none; /* Remove border */
    border-radius: 50px; /* Rounded corners */
    padding:7px 24px; /* Spacing inside the button */
    font-size: 12px; /* Adjust font size */
    font-weight: 500; /* Font weight */
    text-transform: uppercase; /* Make text uppercase */
    cursor: pointer; /* Pointer on hover */
    transition: transform 0.2s ease-in-out; /* Smooth hover effect */
    letter-spacing: 1px;

}

.gradient-btn:hover {
    background: var(--success-light);
}

.theme-font-low {
    font-weight: var(--font-style-low);
}

.about-event-subTitle {
    font-size: var(--font-size-xxl);
    font-weight: 700;
    color: #232323;
}

.theme-color-black{
    color: #232323;
}

.theme-circle{
    width: 60px;
    height: 40px;
    margin-right: 20px;
    font-size: 18px;
    background-color: var(--success-text)
}

.about-count{
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-weight-bolder);
}

.text-title {
    font-size: 2rem;
}

.btn-extra-large {
    padding: 1rem 2rem;
    font-size: 1.25rem;
}

.btn-rounded {
    border-radius: 30px;
}

.with-rounded {
    padding-right: 4.3rem;
    padding-left: 2.9rem;
    font-size: 16px;
}

.popup-with-move-anim {
    transition: all 0.3s ease-in-out;
}

.btn-base-color {
    background-color: var(--success-light); /* Green */
    color: white;
}

.btn.with-rounded>span {
    box-shadow: 0 5px 10px 0 #00000059;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    height: 42px;
    width: 42px;
}

.btn-box-shadow {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Add a shadow */
}

.text-transform-none {
    text-transform: none;
}

.bg-white {
    background-color: white;
}

.text-white {
    color: white;
}

.fa-solid {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
}

.fa-arrow-right {
    content: "\f061";
}

.text-dark-gray {
    color: #333;
}

.form-control, .form-select, input, textarea {
    padding: 12px 25px;
}

.database-menu > div {
    cursor: pointer;
    background: var(--white);
    border: none;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: 0 0 20px #00000001;
    margin-bottom: 15px;
    transition: all .3s cubic-bezier(.12,0,.39,0);
}

.database-menu i {
    margin-right: 10px;
}

.database-menu > div.selected {
    background: var(--white);
    border: none;
    border-radius: 4px;
    padding: 0 12px;
    box-shadow: 0 0 20px #00000014;
    margin-bottom: 15px;
    transition: all .3s cubic-bezier(.12,0,.39,0);
    color: var(--success-light) !important;
}

.database-menu > div.list {
    background: var(--white);
    border: none;
    border-radius: 4px;
    padding: 0 12px;
    box-shadow: 0 0 20px #00000014;
    margin-bottom: 15px;
    transition: all .3s cubic-bezier(.12,0,.39,0);
}

.database-menu div:hover {
    cursor: pointer;
    background: var(--white);
    border: none;
    border-radius: 4px;
    padding: 0 12px;
    box-shadow: 0 0 20px #00000014;
    margin-bottom: 15px;
    transition: all .3s cubic-bezier(.12,0,.39,0);
    color: var(--success-light) !important;
}

.title-text {
    /*font-family: var(--font-family-assistant);*/
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-lg);
    color: var(--black);
}
.header {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
}

.block-left-divider{
    margin-left : 100px
}

.image-tag{
    max-width: 100%;
    height:auto;
}
.card-padding{
    padding: 42px
}


.tab-data-size {
    min-width: 200px;
    height: 68px;
}

.bg-light{
    background-color: var(--gray) !important;
}
.section-title{
    Font-size: var(--font-size-xxxl);
    Font-weight: var(--font-weight-semibold);
    color: var(--primary);
}

.section-sub-title{
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--primary);
}

.section-text{
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);

}

.minimum-line-height{
    line-height: var(--line-spacing-20);
}


.text-gray{
    color: var(--gray-dark) !important;
}

.header-img-text{
     font-size: 52px;
     font-weight: var(--font-weight-semibold);
     padding-top: 140px;
    padding-bottom: 30px;
    color: var(--white) !important;
}
.header-img-sub-text{
    font-size: 32px;
}


.section-divider{
    margin-bottom : 100px
}

.page-container {
    padding-right: 200px;
    padding-left: 200px;
}

.block-container{
    padding-right: 200px;
    padding-left: 200px;
}

@media only screen and (max-width: 1200px) {
    .page-container {
        padding-right: 100px;
        padding-left: 100px;
    }

    .block-container{
        padding-right: 100px;
        padding-left: 100px;
    }
}
.data-section-image-container {
    height: 100px;
    width: 350px;
    margin-bottom: 24px;
}
.about-us-counts-title{
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
}


@media only screen and (max-width: 992px) {
    .section-divider{
        margin-bottom : 50px !important
    }
    .header-img-text{
        font-size: 36px;
    }
    .page-container {
        padding-right: 50px;
        padding-left: 50px;
    }
    .block-container{
        padding-right: 50px;
        padding-left: 50px;
    }
}
@media only screen and (max-width: 576px) {
    .section-divider{
        margin-bottom : 20px !important
    }
    .page-container {
        padding-right: 20px;
        padding-left: 20px;
    }
    .block-container{
        padding-right: 20px;
        padding-left: 20px;
    }
    .data-section-image-container {
        height: auto;
        width: 300px;
        margin-bottom: 24px;
    }
    .header-img-sub-text{
        font-size: var(--font-size-xl);
    }
    .card-container {
        width: 370px;
        max-width: 100%;

    }


}
.primary-text{
    color: var(--primary);
}

.tab-data-size-mobile{
    min-width: max-content;
    height: auto;
}

.database-menu-mobile > div {
    cursor: pointer;
    background: var(--white);
    border: 1px solid var(--gray);
    border-radius: 6px;
    padding: 6px 12px;
    box-shadow: 0 0 20px rgba(8, 8, 8, 0);
    transition: all .3s cubic-bezier(.12,0,.39,0);
}

.database-menu i {
    margin-right: 10px;
}

.database-menu-mobile > div.selected {
    background: var(--white);
    border: 1px solid var(--gray);
    border-radius: 6px;
    padding: 6px 12px;
    box-shadow: 0 0 20px #00000014;
    transition: all .3s cubic-bezier(.12,0,.39,0);
    color: var(--success-light) !important;
}
.database-menu-mobile div:hover {
    cursor: pointer;
    background: var(--white);
    border: 1px solid var(--gray);
    border-radius: 6px;
    padding: 6px 12px;
    box-shadow: 0 0 20px #00000014;
    transition: all .3s cubic-bezier(.12,0,.39,0);
    color: var(--success-light) !important;
}

.cover-background{
  position:relative;
  background-repeat:no-repeat!important;
  overflow:hidden;
    width: 100%;
    background-size:cover;
    background-position:center center!important
}

.cursor{
    cursor: pointer;
}

.accordion-item {
    border: none;
    border-radius: 4px;
    padding: 20px 20px;
    box-shadow: 0 0 20px #00000014;
    margin-bottom: 15px;
    transition: all .3s cubic-bezier(.12,0,.39,0);
}

.box-shadow {
    border: none;
    border-radius: 4px;
    padding: 12px 25px;
    box-shadow: 0 0 45px #00000017;
}
.box-shadow:focus {
    box-shadow: 0 0 45px #00000017;
}

.error-border {
    border: 1px solid var(--danger);
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-item:hover {
    background-color: blue !important;
    color: white !important;
}

.floating-tab {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: linear-gradient(180deg, #67b26f, #4ca2cd);
    padding: 7px;
    border-radius: 11px 0 0 11px;
    writing-mode: vertical-lr;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.floating-tab .tab-text {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 16px;
    color: white;
    text-align: center;
    margin: 0;
}

.floating-tab a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.dot-class {
    display: inline-block;
    content: "•";
    padding-left: .5rem;
    font-weight: bold;
    color: #9B9EA6;
}
.contact-icon{
    max-width: 40px;
}

.slideUpDivSlowly {
    opacity: 0;
    animation: slideUpDivSlowly 1200ms ease-out forwards;
}


.card-heading {
    font-size: var(--font-size-md);
    font-weight: var(--font-style-normal);
}

.card-container {
    width: 300px;
    max-width: 100%;
    min-width: 30%;
}
.card-containerflex-leadership{
    flex: 1;
    max-width: 100%;
    min-width: 320px;
}

.card-containerflex {
    flex: auto;
    min-width: 320px;
    max-width: 23%;
}
@media only screen and (max-width: 768px) {
    .card-containerflex {
        max-width: 100% !important;
    }
}

@keyframes slideUpDivSlowly {
    from {
        transform: translateY(2%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
.gradient-send-btn {
    background: linear-gradient(80deg,#0066b3,var(--success-light)); /* Gradient colors */
    color: white; /* Text color */
    border: none; /* Remove border */
    border-radius: 50px;
    padding: 8px 20px !important;
    font-size: 11px; /* Adjust font size */
    font-weight: 500; /* Font weight */
    cursor: pointer; /* Pointer on hover */
    transition: transform 0.2s ease-in-out; /* Smooth hover effect */
}

.gradient-send-btn:hover {
    background-color: var(--success-light);
    color: white;
}

.data-header{
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-weight-bold);
}

.services-title{
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--primary);
    padding-top: 24px;
    padding-bottom: 16px;
}
.footer-heading{
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
}
.dl-app-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    border: 0;
    width: 100%;
    z-index: 9999;
    color: var(--success-light)
}
.footer-company-link{
    line-height: 30px;
    font-size: var(--font-size-sm);
    color: var(--primary);
    font-weight: var(--font-weight-semibold),
}
.text-header{
    color: var(--primary);
    font-size: var(--font-size-md);
    padding-bottom : 4px
}






