.slideUpInDiv {
    opacity: 0; /* Start with invisible */
    animation: slideUpInDiv 900ms ease-out forwards;
    animation-delay: 700ms;
}

@keyframes slideUpInDiv {
    from {
        transform: translateY(20%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

