.label {
    font-size: var(--font-size-sm);
    height: 30px;
}

#app-header-nav {
    display: flex;
    gap: 18px;
}

.app-header-nav-menu {
    display: flex;
    align-items: center;
    height: 30px;
}

#app-header-nav .app-header-nav-menu:hover {
    color: var(--white)
}

.headerHover {
    font-size: var(--font-size-sm);
    font-family: var(--font-family-inter);
    font-weight: var(--font-style-low) !important;
    /*border-bottom: 2px solid #0007F3 !important;*/
}

.headerHover:hover {
    color: var(--primary);
}

.accordian-title {
    font-size: var(--font-size-md);
}

.feature-menu {
    font-size: var(--font-size-sm);
}

.usecases-menu-selected {
    color: var(--success-light) !important;
    font-weight: var(--font-weight-semibold);
    text-decoration: none !important;
}

.feature-menu-selected {
    color: var(--gray-dark) !important;
    text-decoration: none !important;
}

.dropdown-item a:hover {
    color: var(--link) !important;
}

.app-header-nav-menu a:hover {
    --bs-link-color-rgb: var(--black) !important;
}

.dropdownbtn {
    background: var(--white);
    color: var(--black) ;
    font-size: var(--font-size-sm);
    font-weight: 300;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    border-radius: 10px;
    background-color: white;
    min-width: 260px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {

    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 16px;
    border-bottom: 0.5px solid ;
    border-bottom-color: var(--gray);
}

/* Change color of dropdown links on hover */
/*.dropdown-content a:hover {background-color: #fbf8f8;}*/
.dropdown-content a:hover {color: black !important}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover {
    color: var(--gray);
}

.dropdownbtn:hover{
    color: var(--success-light);
}
.PresentScreen{
    color: var(--primary);
    font-weight: var(--font-style-normal);
    text-decoration: underline;
    text-decoration-color: var(--success-light);
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
}

.nav-link-text{
    font-family: var(--font-family-inter) !important;
    letter-spacing: 0.5px !important;
    color: var(--gray-dark) !important;
    font-weight: var(--font-style-low);
    font-size: var(--font-size-xs)!important;
}
.nav-link-text-selected{
    font-family: var(--font-family-inter) !important;
    letter-spacing: 0.5px !important;
    color: var(--success-light) !important;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-xs)!important;
}

.navbar {
    font-size: var(--font-size-sm);
    line-height: 26px;
    padding: 12px 0;
    color: var(--primary);
}
.navbar:hover {
    color: var(--primary);
}

.block-container-navbar{
    padding: 10px 0 !important;
    line-height: 20px;
}
.block-container-navbar-child{
    padding: 0 !important;
}
/* Change the background color of the dropdown button when the dropdown content is shown */

